import router from './router'
import store from './store'
import {
    getConfigKey
} from "@/api/config";
import { getToken } from "@/utils/auth";

router.beforeEach(async (to, from, next) => {
    if (getToken()) {
        // mqtt 地址获取
        if (store.state.settings.mqttUrl === undefined) {
            try {
                const response = await getConfigKey('sys.index.mqtt')
                store.commit('settings/SET_MQTT_URL', response.rows[0].configValue)
            } catch (error) {
                console.log(error)
            }
        }
    }

    next()
})