import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'

Vue.use(Router)

const routes = [
    // {
    //     path: '/',
    //     name: 'control',
    //     component: () => { return import ('@/views/md/homeIndex.vue') },
    // },
    {
        path: '/110110110',
        name: 'md',
        component: () => { return import ('@/views/md/pageControl.vue') },

    },
    {
        path: '/premission',
        name: 'premission',
        component: () => { return import ('@/views/appPermission.vue') },
    },
    {
        path: '/control',
        name: 'pageControl',
        component: () => { return import ('@/views/md/homeIndex.vue') },
    },
    {
        path: '/timeControl',
        name: 'timeControl',
        component: () => { return import ('@/views/md/timeControl/showInfo.vue') },
    },
    {
        path: '/timeControlDel',
        name: 'timeControlDel',
        component: () => { return import ('@/views/md/timeControl/removeTime.vue') },
    },
    {
        path: '/setTimeControl',
        name: 'setTimeControl',
        component: () => { return import ('@/views/md/timeControl/setTime.vue') },
    },
    {
        path: '/batteryInfo',
        name: 'batteryInfo',
        component: () => { return import ('@/views/md/batteryInfo.vue') },
    },
    {
        path: '/safeInfo',
        name: 'safeInfo',
        component: () => { return import ('@/views/md/safeInfo.vue') },
    },
    //尚为
    {
        path: '/about',
        name: 'about',
        component: () => { return import ('@/views/my/aboutPage.vue') },
    },
    {
        path: '/help',
        name: 'help',
        component: () => { return import ('@/views/my/helpPage.vue') },
    },
    {
        path: '/column',
        name: 'column',
        component: () => { return import ('@/views/my/helpTitle.vue') },
    },
    {
        path: '/helpArticle',
        name: 'helpArticle',
        component: () => { return import ('@/views/my/helpArticle.vue') },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => { return import ('@/views/my/privacyPage.vue') },
    },
    {
        path: '/protocol',
        name: 'protocol',
        component: () => { return import ('@/views/my/protocolPage.vue') },
    },

    // 灯杆项目的H5页面
    {
        path: '/alarmControl',
        name: 'alarmControl',
        component: () => { return import ('@/views/light/alarmControl.vue') },
    },
    {
        path: '/alarmListen',
        name: 'alarmListen',
        component: () => { return import ('@/views/light/alarmListen.vue') },
    },
    {
        path: '/videoControl',
        name: 'videoControl',
        component: () => { return import ('@/views/light/videoControl.vue') },
    },
]

const router = new Router({
    mode: 'history',
    // mode: 'hash',
    routes
})
export default router