<template>
  <div class="containners">
    <!-- <img src="./assets/logo.png"> -->
    <router-view />
    <!-- <el-botton @click="to">index</el-botton> -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html,
body {
  height: 100vh;
  width: 100vw;
}
</style>
