// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import "./styles/reset.scss"
import "./styles/commons.scss"

import './permission' // permission control


import {
    Button,
    Popover,
    Checkbox,
    CheckboxGroup,
    Toast,
    Dialog,
    Popup,
    RadioGroup,
    Radio,
    DatetimePicker,
    Cell,
    CellGroup,
    List,
    PullRefresh,
    Field,
    Slider,
    Picker
} from 'vant'
import {
    Tree,
    Slider as ESlider
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import 'vant/lib/index.css'

import vconsole from "vconsole"

process.env.VUE_APP_BASE_API === "/dev-api" && new vconsole()
// new vconsole()

Vue.use(Tree)
Vue.use(ESlider)


Vue.use(Button)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Popup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Field)
Vue.use(Slider)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})