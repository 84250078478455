

const state = {
  mqttUrl: undefined
}

const mutations = {
  SET_MQTT_URL: (state, mqttUrl) => {
    state.mqttUrl = mqttUrl
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}

