const accessTokenKey = 'YL_ACCESS_TOKEN'
const accessDeviceKey = 'YL_DEVICE_DETAILS'

export function getToken() {
  return window.localStorage.getItem(accessTokenKey)
}

export function removeToken() {
  return window.localStorage.removeItem(accessTokenKey)
}

export function setToken(token) {
  window.localStorage.setItem(accessTokenKey, token)
}

export function getDeviceDetails() {

  let value = window.localStorage.getItem(accessDeviceKey)
  try {
    value = JSON.parse(value)
  } 
  /* eslint-disable */
  catch (error) {}

  return value
}

export function removeDeviceDetails() {
  return window.localStorage.removeItem(accessDeviceKey)
}

export function setDeviceDetails(value) {

  try {
    value = JSON.stringify(value)
  } 
  /* eslint-disable */
  catch (error) {}

  window.localStorage.setItem(accessDeviceKey, value)
}